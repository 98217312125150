import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout/Layout';
import HeroImage from '../svg/HeroImage';

export default () => (
  <Layout>
    <Helmet>
      <title>キュブライト株式会社</title>
      <description>
        ブライト・ユア・ライフをKUBRIGHTは目指します。
        あなたの暮らしをより豊かに、便利に、価値あるものへと導き、
        輝かしい縁（円en）を創造していきます。
      </description>
      <meta property="og:url" content="https://www.kubright.co.jp" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="キュブライト株式会社" />
      <meta
        property="og:description"
        content="ブライト・ユア・ライフをKUBRIGHTは目指します。
        あなたの暮らしをより豊かに、便利に、価値あるものへと導き、
        輝かしい縁（円en）を創造していきます。"
      />
      <meta property="og:site_name" content="キュブライト株式会社" />
      <script src="https://sdk.form.run/js/v2/embed.js" defer />
    </Helmet>
    <section className="pt-20 md:pt-20">
      <div className="container mx-auto px-8 lg:flex items-center">
        <div className="text-center lg:text-left lg:w-1/2">
          <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
            あなたの暮らしに
            <br />
            美しい輝きを
          </h1>
          <p className="text-xl lg:text-2xl mt-12 font-light">
            ブライト・ユア・ライフをKUBRIGHTは目指します。
            あなたの暮らしをより豊かに、便利に、価値あるものへと導き、
            輝かしい縁（円en）を創造していきます。
          </p>
        </div>
        <div className="lg:w-1/2 ml-8">
          <HeroImage />
        </div>
      </div>
    </section>
    <section id="company" className="px-8 py-20 lg:pb-40 lg:pt-48">
      <div className="container mx-auto">
        <h2 className="mb-12 text-3xl lg:text-5xl font-semibold text-center">会社概要</h2>

        <div className="w-full sm:w-full md:w-3/4 lg:w-2/4 mx-auto divide-y divide-gray-400 pb-8">
          <div className="flex items-center py-4">
            <div className="w-2/5 py-2 text-gray-600">社名</div>
            <div className="w-3/5 py-2">キュブライト株式会社 (Kubright Inc.)</div>
          </div>
        </div>
      </div>
    </section>
    <section
      id="contact"
      className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center"
    >
      {/* <h2 className="mb-12 text-2xl lg:text-3xl font-semibold text-center">お問い合わせ</h2> */}
      <div
        className="formrun-embed mx-8 sm:mx-8 md:mx-12 lg:mx-32"
        data-formrun-form="@kubright-1602660991"
        data-formrun-redirect="true"
      />
    </section>
  </Layout>
);
