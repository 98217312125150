import React from 'react';

const HeroImage = () => (
  <svg
    version="1.0"
    id="OBJECTS"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 500 500"
    enableBackground="new 0 0 500 500"
    xmlSpace="preserve"
  >
    <path
      fill="#FFFF6B"
      d="M399.242,470.581c60.924-45.162,100.419-117.592,100.419-199.254c0-136.899-110.979-247.878-247.878-247.878
	S3.906,134.428,3.906,271.327c0,81.662,39.495,154.092,100.42,199.254H399.242z"
    />
    <g>
      <g id="XMLID_2434_">
        <circle id="XMLID_2435_" fill="#FFAF82" cx="405.818" cy="174.809" r="7.996" />
      </g>
      <g id="XMLID_2432_">
        <path
          id="XMLID_2433_"
          fill="#56423B"
          d="M403.013,175.327h-3.19c-0.883,0-1.599-0.716-1.599-1.599v-24.669h6.388v24.669
			C404.612,174.611,403.896,175.327,403.013,175.327z"
        />
      </g>
      <g>
        <path
          fill="#FFAF82"
          d="M404.964,226.507c-4.762,0-27.279-1.051-45.734-19.992c-18.685-19.176-26.737-49.621-23.934-90.49
			c0.316-4.618,4.266-8.135,8.938-7.79c4.619,0.317,8.107,4.318,7.789,8.937c-2.451,35.726,3.94,61.773,18.995,77.415
			c14.964,15.547,33.395,15.182,34.159,15.151c4.658-0.153,8.491,3.466,8.658,8.073c0.168,4.607-3.389,8.484-7.994,8.68
			C405.744,226.496,405.444,226.507,404.964,226.507z"
        />
      </g>
      <g>
        <path
          fill="#FAC855"
          d="M394.869,206.646l21.348,4.185c5.537,1.086,9.146,6.455,8.06,11.992c-1.086,5.537-6.455,9.146-11.992,8.06
			l-21.348-4.186L394.869,206.646z"
        />
      </g>
      <rect
        id="XMLID_2431_"
        x="415.665"
        y="362.064"
        fill="#F9C388"
        width="14.721"
        height="98.576"
      />
      <rect
        id="XMLID_2430_"
        x="443.729"
        y="362.064"
        fill="#F9C388"
        width="14.721"
        height="98.576"
      />
      <rect
        id="XMLID_2429_"
        x="410.317"
        y="300.713"
        fill="#965028"
        width="23.999"
        height="107.048"
      />
      <polygon
        id="XMLID_2428_"
        fill="#965028"
        points="462.362,407.761 438.363,407.761 438.363,313.812 462.362,300.713 	"
      />
      <rect
        id="XMLID_2427_"
        x="410.317"
        y="300.713"
        fill="#965028"
        width="52.045"
        height="42.022"
      />
      <rect id="XMLID_2426_" x="407.374" y="403.833" fill="#A56941" width="26.942" height="7.24" />
      <rect id="XMLID_2425_" x="433.792" y="403.833" fill="#B48255" width="28.919" height="7.24" />
      <rect id="XMLID_2424_" x="387.409" y="469.133" fill="#235559" width="45.854" height="2.996" />

      <rect
        id="XMLID_2423_"
        x="414.79"
        y="469.133"
        transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 875.4335 941.2618)"
        fill="#286569"
        width="45.854"
        height="2.996"
      />
      <path
        id="XMLID_2422_"
        fill="#30767A"
        d="M432.147,469.133h-41.828v-5.234c0-2.587,2.098-4.685,4.685-4.685h37.143V469.133z"
      />
      <path
        id="XMLID_2421_"
        fill="#38979E"
        d="M458.631,469.133h-41.828v-5.234c0-2.587,2.098-4.685,4.685-4.685h37.143V469.133z"
      />
      <path
        id="XMLID_2420_"
        fill="#FFE678"
        d="M464.499,316.995h-57.348V214.327c0-2.349,1.904-4.253,4.253-4.253h49.332
		c2.079,0,3.764,1.685,3.764,3.764V316.995z"
      />
      <path
        fill="#FAC855"
        d="M442.043,210.074v3.4c0,7.151-5.797,12.948-12.948,12.948c-7.151,0-12.948-5.797-12.948-12.948v-3.4
		H442.043z"
      />
      <path
        id="XMLID_2419_"
        fill="#FFAF82"
        d="M429.095,219.803L429.095,219.803c-4.355,0-7.885-3.53-7.885-7.885V193.88h15.77v18.038
		C436.98,216.273,433.45,219.803,429.095,219.803z"
      />
      <g id="XMLID_2417_">
        <path
          id="XMLID_2418_"
          fill="#56423B"
          d="M458.537,140.553c0,11.229-13.536,20.331-30.234,20.331
			c-16.698,0-30.234-9.103-30.234-20.331s13.536-16.103,30.234-16.103C445.001,124.45,458.537,129.325,458.537,140.553z"
        />
      </g>
      <g id="XMLID_2415_">
        <path
          id="XMLID_2416_"
          fill="#FFCD9B"
          d="M438.662,206.392h-26.921c-5.845,0-10.584-4.739-10.584-10.584v-52.582h53.908v46.762
			C455.066,199.048,447.722,206.392,438.662,206.392z"
        />
      </g>
      <g id="XMLID_2413_">
        <path
          id="XMLID_2414_"
          fill="#654932"
          d="M410.192,184.437c-0.803,0.005-1.289,1.013-0.875,1.801
			c1.745,3.323,4.93,5.539,8.562,5.518c3.632-0.021,6.798-2.272,8.514-5.615c0.407-0.793-0.087-1.796-0.89-1.792L410.192,184.437z"
        />
      </g>
      <g id="XMLID_2408_">
        <defs>
          <path
            id="XMLID_2410_"
            d="M410.192,184.437c-0.803,0.005-1.289,1.013-0.875,1.801c1.745,3.323,4.93,5.539,8.562,5.518
				c3.632-0.021,6.798-2.272,8.514-5.615c0.407-0.793-0.087-1.796-0.89-1.792L410.192,184.437z"
          />
        </defs>
        <clipPath id="XMLID_12_">
          <use xlinkHref="#XMLID_2410_" overflow="visible" />
        </clipPath>
        <g id="XMLID_2411_" clipPath="url(#XMLID_12_)">
          <path
            id="XMLID_2412_"
            fill="#D34C4C"
            d="M424.845,194.815c0,4.422-3.131,8.007-6.992,8.007c-3.862,0-6.992-3.585-6.992-8.007
				c0-4.423,3.131-8.008,6.992-8.008C421.715,186.807,424.845,190.392,424.845,194.815z"
          />
        </g>
      </g>
      <g id="XMLID_2406_">
        <path
          id="XMLID_2407_"
          fill="#56423B"
          d="M445.827,178.673h-3.19c-0.883,0-1.599-0.716-1.599-1.599v-41.638h6.387v41.638
			C447.426,177.957,446.71,178.673,445.827,178.673z"
        />
      </g>
      <g id="XMLID_2404_">
        <path
          id="XMLID_2405_"
          fill="#56423B"
          d="M452.88,182.123h-5.454v-42.872h10.921v37.405
			C458.347,179.675,455.899,182.123,452.88,182.123z"
        />
      </g>
      <g id="XMLID_2402_">
        <path
          id="XMLID_2403_"
          fill="#FFCD9B"
          d="M447.345,167.996c-0.344,0-0.68,0.029-1.013,0.071v15.85
			c0.333,0.042,0.669,0.071,1.013,0.071c4.416,0,7.996-3.58,7.996-7.996C455.341,171.576,451.761,167.996,447.345,167.996z"
        />
      </g>
      <g id="XMLID_2397_">
        <g id="XMLID_2400_">
          <path
            id="XMLID_2401_"
            fill="#654932"
            d="M406.414,170.721c-1.52,0-2.944,1.016-3.81,2.718c-0.198,0.389-0.134,0.93,0.143,1.208
				c0.277,0.278,0.662,0.188,0.86-0.201c0.644-1.267,1.667-1.993,2.806-1.993c1.139,0,2.162,0.726,2.807,1.993
				c0.12,0.236,0.31,0.363,0.502,0.363c0.124,0,0.249-0.052,0.358-0.162c0.277-0.278,0.341-0.819,0.143-1.208
				C409.358,171.737,407.933,170.72,406.414,170.721z"
          />
        </g>
        <g id="XMLID_2398_">
          <path
            id="XMLID_2399_"
            fill="#654932"
            d="M434.443,174.647c0.389-0.278,0.479-0.819,0.201-1.208
				c-1.216-1.702-3.217-2.718-5.352-2.718c-2.135,0-4.136,1.016-5.352,2.718c-0.278,0.389-0.188,0.93,0.201,1.208
				c0.389,0.278,0.93,0.188,1.208-0.201c0.905-1.267,2.342-1.993,3.943-1.993c1.6,0,3.037,0.726,3.942,1.993
				c0.169,0.236,0.435,0.363,0.705,0.363C434.114,174.809,434.29,174.756,434.443,174.647z"
          />
        </g>
      </g>
      <path
        fill="#56423B"
        d="M387.503,137.465c0,7.54,6.112,13.652,13.653,13.652h53.294v-13.652H387.503z"
      />

      <rect
        x="379.491"
        y="212.745"
        transform="matrix(-0.1924 0.9813 -0.9813 -0.1924 676.6538 -124.8226)"
        fill="#FFE678"
        width="20.398"
        height="6.556"
      />
      <path
        fill="#A5C8CD"
        d="M486.739,332.116H426.77c-1.512,0-2.737-1.225-2.737-2.737v-41.733c0-1.512,1.225-2.737,2.737-2.737h59.969
		c1.512,0,2.737,1.225,2.737,2.737v41.733C489.476,330.891,488.25,332.116,486.739,332.116z"
      />
      <path
        fill="#FFFFFF"
        d="M483.55,332.116h-59.968c-1.512,0-2.737-1.225-2.737-2.737v-41.733c0-1.512,1.225-2.737,2.737-2.737h59.969
		c1.512,0,2.737,1.225,2.737,2.737v41.733C486.287,330.891,485.062,332.116,483.55,332.116z"
      />
      <path
        fill="#6C9BAA"
        d="M460.815,311.541c0,4.885-3.96,8.846-8.846,8.846c-4.885,0-8.846-3.96-8.846-8.846
		c0-4.885,3.96-8.846,8.846-8.846C456.855,302.695,460.815,306.655,460.815,311.541z"
      />
      <g>
        <path
          fill="#FFAF82"
          d="M461.282,340.5c-4.63,0-8.384-3.754-8.384-8.384v-114c0-4.63,3.754-8.384,8.384-8.384
			s8.384,3.754,8.384,8.384v114C469.666,336.746,465.912,340.5,461.282,340.5z"
        />
      </g>
      <g>
        <path
          fill="#FAC855"
          d="M451.065,241.573v-21.754c0-5.642,4.575-10.217,10.217-10.217c5.642,0,10.217,4.575,10.217,10.217v21.754
			H451.065z"
        />
      </g>
      <rect x="451.065" y="241.573" fill="#FFE678" width="20.398" height="6.556" />
    </g>
    <g>
      <g>
        <g>
          <path
            fill="#FFAF82"
            d="M211.556,231.72H102.42c-4.598,0-8.326-3.728-8.326-8.326c0-4.598,3.728-8.326,8.326-8.326h109.136
				c4.598,0,8.326,3.728,8.326,8.326C219.882,227.992,216.154,231.72,211.556,231.72z"
          />
        </g>
        <path
          fill="#FA870A"
          d="M130.197,211.625H89.828v9.953c0,6.562,4.777,11.882,10.669,11.882h29.699V211.625z"
        />
      </g>
      <g>
        <path
          fill="none"
          stroke="#EB9F5A"
          strokeWidth="8.033"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="M69.791,144.301
			c0,0-20.999,95.263,19.103,19.701"
        />

        <rect
          id="XMLID_1543_"
          x="57.635"
          y="447.027"
          transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 129.892 895.3443)"
          fill="#408DBC"
          width="14.621"
          height="1.29"
        />
        <path
          id="XMLID_1542_"
          fill="#5046AF"
          d="M24.579,412.815l14.97,18.468l58.389-37.041c4.736-3.005,7.606-8.224,7.606-13.833
			v-86.608H81.709l0.626,80.642L24.579,412.815z"
        />
        <polygon
          id="XMLID_1541_"
          fill="#6E64C3"
          points="53.751,454.549 77.586,454.549 78.295,337.956 52.712,343.076 		"
        />
        <polygon
          id="XMLID_1540_"
          fill="#6E64C3"
          points="52.712,343.076 105.544,339.037 105.544,301.88 52.712,301.88 		"
        />

        <rect
          id="XMLID_1539_"
          x="16.69"
          y="421.81"
          transform="matrix(-0.6297 -0.7768 0.7768 -0.6297 -281.6058 716.9408)"
          fill="#41378C"
          width="26.758"
          height="7.555"
        />

        <rect
          id="XMLID_1538_"
          x="53.404"
          y="452.114"
          transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 135.5306 911.7836)"
          fill="#5046AF"
          width="28.722"
          height="7.555"
        />

        <rect
          id="XMLID_1537_"
          x="2.413"
          y="441.495"
          transform="matrix(-0.6297 -0.7768 0.7768 -0.6297 -303.0811 741.4955)"
          fill="#172F40"
          width="45.542"
          height="2.975"
        />
        <rect
          id="XMLID_1536_"
          x="50.383"
          y="469.153"
          fill="#172F40"
          width="45.542"
          height="2.975"
        />
        <path
          id="XMLID_1535_"
          fill="#132736"
          d="M12.699,425.218l26.16,32.272l4.038-3.273c1.997-1.618,2.303-4.549,0.685-6.545
			l-23.23-28.658L12.699,425.218z"
        />
        <path
          id="XMLID_1534_"
          fill="#172F40"
          d="M52.383,469.153h41.544v-5.198c0-2.57-2.083-4.653-4.653-4.653H52.383V469.153z"
        />
        <path
          id="XMLID_1533_"
          fill="#FFA50A"
          d="M51.731,318.051h56.957V216.448c0-2.534-2.055-4.589-4.589-4.589H55.456
			c-2.057,0-3.724,1.667-3.724,3.724V318.051z"
        />
        <path
          id="XMLID_1532_"
          fill="#FFAF82"
          d="M78.853,221.521h6.516c2.526,0,4.574-2.048,4.574-4.574v-20.519
			c0-0.361-0.292-0.653-0.653-0.653H74.933c-0.361,0-0.653,0.292-0.653,0.653v20.519C74.28,219.473,76.327,221.521,78.853,221.521z"
        />
        <g id="XMLID_1511_">
          <g id="XMLID_1530_">
            <path
              id="XMLID_1531_"
              fill="#FFAF82"
              d="M97.288,176.833c0,4.386,3.556,7.941,7.942,7.941s7.941-3.555,7.941-7.941
					s-3.555-7.942-7.941-7.942S97.288,172.447,97.288,176.833z"
            />
          </g>
          <g id="XMLID_1528_">
            <path
              id="XMLID_1529_"
              fill="#FFCD9B"
              d="M72.609,208.201h26.738c5.806,0,10.512-4.706,10.512-10.512v-52.224H56.317v46.444
					C56.317,200.907,63.611,208.201,72.609,208.201z"
            />
          </g>
          <g id="XMLID_1526_">
            <path
              id="XMLID_1527_"
              fill="#654932"
              d="M100.886,186.396c0.798,0.004,1.28,1.006,0.869,1.789
					c-1.733,3.301-4.897,5.501-8.504,5.481c-3.607-0.02-6.752-2.257-8.457-5.577c-0.404-0.788,0.086-1.784,0.884-1.779
					L100.886,186.396z"
            />
          </g>
          <g id="XMLID_1521_">
            <defs>
              <path
                id="XMLID_1523_"
                d="M100.886,186.396c0.798,0.004,1.28,1.006,0.869,1.789c-1.733,3.301-4.897,5.501-8.504,5.481
						c-3.607-0.02-6.752-2.257-8.456-5.577c-0.404-0.788,0.086-1.784,0.884-1.779L100.886,186.396z"
              />
            </defs>
            <clipPath id="XMLID_13_">
              <use xlinkHref="#XMLID_1523_" overflow="visible" />
            </clipPath>
            <g id="XMLID_1524_" clipPath="url(#XMLID_13_)">
              <path
                id="XMLID_1525_"
                fill="#D34C4C"
                d="M86.332,196.703c0,4.392,3.109,7.953,6.945,7.953c3.835,0,6.945-3.561,6.945-7.953
						c0-4.392-3.109-7.953-6.945-7.953C89.441,188.75,86.332,192.31,86.332,196.703z"
              />
            </g>
          </g>
          <path
            fill="#783C28"
            d="M114.294,149.729c0-3.315-1.341-6.317-3.509-8.494c-1.035-9.048-10.988-13.496-23.78-14.277l1.112-0.973
				c0.357-0.313,0.394-0.856,0.081-1.214c-0.313-0.356-0.856-0.393-1.214-0.081l-1.382,1.209v-1.662c0-0.95-0.77-1.721-1.721-1.721
				s-1.721,0.77-1.721,1.721v1.533l-1.258-1.172c-0.348-0.323-0.891-0.304-1.216,0.043c-0.324,0.348-0.304,0.892,0.043,1.216
				l1.073,1c-14.2,0.39-25.468,5.032-26.082,15.1c-1.777,2.098-2.853,4.808-2.853,7.772c0,6.648,5.389,12.037,12.037,12.037v16.373
				c0,1.398,1.133,2.532,2.532,2.532h1.281c1.398,0,2.531-1.133,2.531-2.532v-17.271c3.777,1.354,8.022,2.135,12.53,2.135
				c5.042,0,9.763-0.965,13.856-2.634c1.679,0.889,3.59,1.397,5.622,1.397c1.469,0,2.87-0.276,4.171-0.757v5.351
				c0,1.398,1.133,2.531,2.531,2.531h1.281c1.398,0,2.531-1.133,2.531-2.531v-10.778
				C113.738,153.848,114.294,151.855,114.294,149.729z"
          />
          <g id="XMLID_1519_">
            <path
              id="XMLID_1520_"
              fill="#783C28"
              d="M58.488,184.098h5.417v-32.433H53.058v27.003
					C53.058,181.666,55.489,184.098,58.488,184.098z"
            />
          </g>
          <g id="XMLID_1517_">
            <path
              id="XMLID_1518_"
              fill="#FFCD9B"
              d="M63.561,168.892c0.368,0,0.727,0.031,1.083,0.076v16.939
					c-0.355,0.045-0.715,0.076-1.083,0.076c-4.719,0-8.545-3.826-8.545-8.545C55.015,172.717,58.841,168.892,63.561,168.892z"
            />
          </g>
          <g id="XMLID_1512_">
            <g id="XMLID_1515_">
              <path
                id="XMLID_1516_"
                fill="#654932"
                d="M104.638,172.773c1.509,0,2.924,1.009,3.784,2.699c0.197,0.387,0.133,0.924-0.142,1.2
						c-0.275,0.276-0.658,0.187-0.854-0.2c-0.64-1.258-1.656-1.979-2.787-1.979c-1.131,0-2.147,0.721-2.788,1.979
						c-0.12,0.235-0.308,0.36-0.499,0.36c-0.123,0-0.248-0.052-0.356-0.161c-0.275-0.276-0.339-0.814-0.142-1.2
						C101.714,173.782,103.129,172.773,104.638,172.773z"
              />
            </g>
            <g id="XMLID_1513_">
              <path
                id="XMLID_1514_"
                fill="#654932"
                d="M76.6,175.472c1.208-1.69,3.195-2.7,5.315-2.699c2.12,0,4.108,1.009,5.315,2.699
						c0.276,0.387,0.187,0.924-0.199,1.2c-0.386,0.276-0.924,0.187-1.2-0.2c-0.899-1.258-2.326-1.979-3.916-1.979
						c-1.589,0-3.016,0.721-3.916,1.979c-0.168,0.235-0.432,0.36-0.701,0.36c-0.173,0-0.348-0.052-0.5-0.161
						C76.413,176.397,76.324,175.859,76.6,175.472z"
              />
            </g>
          </g>
        </g>
        <g>
          <path
            fill="#FFCD9B"
            d="M65.624,291.252c-15.449,0-25.946-4.21-31.757-12.653c-14.854-21.576,11.374-58.88,14.413-63.063
				c2.703-3.72,7.908-4.546,11.63-1.841c3.72,2.703,4.544,7.91,1.841,11.63c-9.172,12.666-20.016,35.352-14.166,43.836
				c1.948,2.823,11.725,11.111,55.79-0.774c4.442-1.2,9.01,1.43,10.207,5.87c1.198,4.44-1.431,9.01-5.87,10.208
				C90.951,288.986,76.955,291.252,65.624,291.252z"
          />
        </g>
        <g>
          <path
            fill="#FA870A"
            d="M50.364,249.907c3.401-8.287,8.334-16.841,12.881-23.121c3.088-4.257,2.144-10.211-2.11-13.302
				c-4.258-3.094-10.217-2.151-13.309,2.106c-1.676,2.306-9.786,13.819-15.332,27.707L50.364,249.907z"
          />
        </g>
        <g>
          <path
            fill="#3C5A73"
            d="M61.578,264.955L61.578,264.955l45.36-6.431l21.477-40.34l0,0c4.663,2.482,6.43,8.275,3.948,12.937
				l-19.277,36.208L72.36,273.09C67.134,273.83,62.302,270.183,61.578,264.955z"
          />
        </g>
        <polygon
          fill="#97F4EC"
          points="106.938,258.524 126.432,221.908 124.665,221.078 105.92,256.437 		"
        />
        <polygon
          fill="#50555A"
          points="105.92,256.437 106.938,258.524 65.062,264.461 65.062,262.329 		"
        />
      </g>
    </g>
    <g>
      <rect x="284.966" y="171.55" fill="#E87D31" width="61.747" height="60.538" />
      <rect id="XMLID_1219_" x="297.236" y="380.64" fill="#F9C388" width="12.857" height="81.454" />
      <rect id="XMLID_1218_" x="324.17" y="380.64" fill="#F9C388" width="12.857" height="81.454" />
      <rect id="XMLID_1217_" x="297.236" y="380.64" opacity="0.2" width="12.857" height="7.657" />
      <rect id="XMLID_1216_" x="324.17" y="380.64" opacity="0.2" width="12.857" height="7.657" />
      <rect id="XMLID_1214_" x="289.555" y="454.969" fill="#828291" width="11.183" height="7.938" />

      <rect
        id="XMLID_1213_"
        x="333.607"
        y="454.969"
        transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 678.3971 917.8766)"
        fill="#828291"
        width="11.183"
        height="7.938"
      />
      <rect id="XMLID_1212_" x="272.558" y="469.512" fill="#50374B" width="25.279" height="2.616" />
      <rect id="XMLID_1211_" x="301.354" y="469.512" fill="#50374B" width="11.82" height="2.616" />
      <rect id="XMLID_1210_" x="336.829" y="469.512" fill="#50374B" width="24.176" height="2.616" />

      <rect
        id="XMLID_1209_"
        x="321.244"
        y="469.512"
        transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 653.9545 941.6412)"
        fill="#50374B"
        width="11.467"
        height="2.616"
      />
      <path
        id="XMLID_1208_"
        fill="#695A6E"
        d="M311.631,469.512h-36.532l0,0c0-4.784,3.879-8.663,8.663-8.663h27.869V469.512z"
      />
      <path
        id="XMLID_1207_"
        fill="#695A6E"
        d="M322.715,469.512h36.532l0,0c0-4.784-3.879-8.663-8.663-8.663h-27.869V469.512z"
      />

      <ellipse
        id="XMLID_1195_"
        transform="matrix(0.2279 -0.9737 0.9737 0.2279 76.8979 440.0701)"
        fill="#E87D31"
        cx="315.92"
        cy="171.55"
        rx="30.953"
        ry="30.953"
      />
      <path
        fill="#FFAF82"
        d="M322.665,225.543c0,4.093-3.318,7.411-7.411,7.411c-4.093,0-7.411-3.318-7.411-7.411v-8.334h14.822V225.543
		z"
      />
      <circle id="XMLID_1194_" fill="#FFAF82" cx="291.263" cy="189.776" r="7.629" />
      <circle id="XMLID_1193_" fill="#FFAF82" cx="339.171" cy="189.776" r="7.629" />
      <path
        id="XMLID_1192_"
        fill="#FFCD9B"
        d="M324.973,219.91h-19.172c-8.909,0-16.132-7.222-16.132-16.132v-44.136h51.435v44.136
		C341.105,212.688,333.882,219.91,324.973,219.91z"
      />
      <path
        id="XMLID_1191_"
        fill="#FFFFFF"
        d="M307.016,198.962c-0.878,0.004-1.408,0.967-0.956,1.719
		c1.907,3.171,5.387,5.285,9.355,5.265c3.969-0.02,7.428-2.168,9.303-5.358c0.445-0.757-0.095-1.714-0.973-1.709L307.016,198.962z"
      />
      <g id="XMLID_1188_">
        <path
          id="XMLID_1190_"
          fill="#654932"
          d="M304.473,185.297c-2.037,0-3.946,0.969-5.106,2.593c-0.266,0.372-0.18,0.888,0.192,1.153
			c0.371,0.265,0.887,0.179,1.153-0.192c0.864-1.209,2.234-1.902,3.761-1.902c1.527,0,2.897,0.693,3.762,1.902
			c0.161,0.226,0.415,0.346,0.673,0.346c0.166,0,0.334-0.05,0.48-0.154c0.371-0.265,0.457-0.782,0.192-1.153
			C308.418,186.267,306.509,185.297,304.473,185.297z"
        />
        <path
          id="XMLID_1189_"
          fill="#654932"
          d="M331.407,187.891c-1.161-1.624-3.069-2.594-5.106-2.593
			c-2.037,0-3.946,0.969-5.106,2.593c-0.266,0.372-0.18,0.888,0.192,1.153c0.371,0.265,0.888,0.179,1.153-0.192
			c0.864-1.209,2.234-1.902,3.762-1.902c1.527,0,2.897,0.693,3.762,1.902c0.161,0.226,0.415,0.346,0.673,0.346
			c0.166,0,0.334-0.05,0.48-0.154C331.587,188.778,331.673,188.262,331.407,187.891z"
        />
      </g>
      <g id="XMLID_1183_">
        <defs>
          <ellipse
            id="XMLID_1184_"
            transform="matrix(0.2279 -0.9737 0.9737 0.2279 76.8979 440.0701)"
            cx="315.92"
            cy="171.55"
            rx="30.953"
            ry="30.953"
          />
        </defs>
        <clipPath id="XMLID_14_">
          <use xlinkHref="#XMLID_1184_" overflow="visible" />
        </clipPath>
        <g id="XMLID_1185_" clipPath="url(#XMLID_14_)">
          <path
            id="XMLID_1187_"
            fill="#E87D31"
            d="M323.717,117.059c-5.87,1.978-11.387,5.692-15.702,11.028
				c-11.206,13.855-10.078,33.347,2.52,43.535c12.598,10.188,31.894,7.216,43.1-6.64c4.315-5.336,6.794-11.507,7.5-17.661
				L323.717,117.059z"
          />
          <path
            id="XMLID_1186_"
            fill="#E87D31"
            d="M281.596,140.596c-2.73,0-5.335,0.48-7.727,1.344v35.404
				c2.391,0.864,4.997,1.344,7.727,1.344c11.569,0,20.947-8.527,20.947-19.046S293.165,140.596,281.596,140.596z"
          />
        </g>
      </g>
      <path
        fill="#FFCD9B"
        d="M289.376,288.967l6.737,34.244h37.05l7.859-37.051c0-4.86,2.413-8.158,5.69-9.912v-49.796H285.34v49.425
		C287.74,277.774,289.376,284.972,289.376,288.967z"
      />
      <polygon
        fill="#E16450"
        points="337.523,332.779 291.842,332.779 287.668,270.689 341.697,270.689 	"
      />
      <path
        fill="#FA503C"
        d="M335.348,252.815v-1.113h-37.241v0.734c-7.802,1.358-13.789,9.101-13.789,18.478
		c0,10.334,7.264,18.711,16.224,18.711c3.207,0,6.195-1.075,8.71-2.928c4.062-2.994,9.274-2.996,13.335-0.001
		c2.515,1.854,5.503,2.93,8.711,2.93c8.96,0,16.224-8.377,16.224-18.711C347.521,262.196,342.344,254.893,335.348,252.815z"
      />
      <path
        opacity="0.36"
        fill="#E16450"
        d="M296.535,265.984L296.535,265.984c-3.237,0-5.778-2.775-5.493-5.999l2.815-31.938h5.356
		l2.815,31.938C302.312,263.209,299.771,265.984,296.535,265.984z"
      />
      <path
        opacity="0.36"
        fill="#E16450"
        d="M334.54,265.984L334.54,265.984c-3.237,0-5.778-2.775-5.493-5.999l2.815-31.938h5.356
		l2.815,31.938C340.318,263.209,337.777,265.984,334.54,265.984z"
      />
      <path
        fill="#FA968C"
        d="M296.535,264.39L296.535,264.39c-3.237,0-5.778-2.775-5.493-5.999l2.815-31.938h5.356l2.815,31.938
		C302.312,261.615,299.771,264.39,296.535,264.39z"
      />
      <path
        fill="#FA968C"
        d="M334.54,264.39L334.54,264.39c-3.237,0-5.778-2.775-5.493-5.999l2.815-31.938h5.356l2.815,31.938
		C340.318,261.615,337.777,264.39,334.54,264.39z"
      />
      <path
        fill="#FA503C"
        d="M352.024,392.195c1.208-9.373,1.871-19.374,1.871-29.775c0-52.748-16.811-95.51-37.549-95.51
		c-20.738,0-37.549,42.761-37.549,95.51c0,10.4,0.662,20.402,1.871,29.775H352.024z"
      />
      <path
        opacity="0.36"
        fill="#E16450"
        d="M296.535,255.601c-1.954,0-3.537,1.584-3.537,3.537s1.584,3.537,3.537,3.537
		c1.953,0,3.537-1.584,3.537-3.537S298.488,255.601,296.535,255.601z"
      />
      <g>
        <path
          fill="#A55A3C"
          d="M300.072,258.292c0,1.954-1.584,3.537-3.537,3.537s-3.537-1.584-3.537-3.537
			c0-1.953,1.584-3.537,3.537-3.537S300.072,256.338,300.072,258.292z"
        />
        <path
          fill="#965237"
          d="M298.743,258.292c0,1.22-0.989,2.209-2.209,2.209c-1.22,0-2.209-0.989-2.209-2.209
			c0-1.22,0.989-2.209,2.209-2.209C297.754,256.083,298.743,257.072,298.743,258.292z"
        />
        <circle fill="#783C28" cx="295.665" cy="258.227" r="0.483" />
        <path
          fill="#783C28"
          d="M297.861,258.227c0,0.267-0.216,0.483-0.483,0.483c-0.267,0-0.483-0.216-0.483-0.483
			c0-0.267,0.216-0.483,0.483-0.483C297.645,257.744,297.861,257.96,297.861,258.227z"
        />
      </g>
      <path
        opacity="0.36"
        fill="#E16450"
        d="M334.54,255.597c-1.954,0-3.537,1.584-3.537,3.537s1.584,3.537,3.537,3.537
		s3.537-1.584,3.537-3.537S336.493,255.597,334.54,255.597z"
      />
      <g>
        <path
          fill="#A55A3C"
          d="M338.077,258.287c0,1.954-1.584,3.537-3.537,3.537s-3.537-1.584-3.537-3.537
			c0-1.953,1.584-3.537,3.537-3.537S338.077,256.333,338.077,258.287z"
        />
        <path
          fill="#965237"
          d="M336.749,258.287c0,1.22-0.989,2.209-2.209,2.209c-1.22,0-2.209-0.989-2.209-2.209
			c0-1.22,0.989-2.209,2.209-2.209C335.76,256.078,336.749,257.067,336.749,258.287z"
        />
        <path
          fill="#783C28"
          d="M334.154,258.222c0,0.267-0.216,0.483-0.483,0.483c-0.267,0-0.483-0.216-0.483-0.483
			c0-0.267,0.216-0.483,0.483-0.483C333.937,257.739,334.154,257.955,334.154,258.222z"
        />
        <circle fill="#783C28" cx="335.383" cy="258.222" r="0.483" />
      </g>
      <path
        opacity="0.32"
        fill="#FFFFFF"
        d="M311.368,188.945c0,5.146-4.172,9.318-9.318,9.318c-5.146,0-9.318-4.172-9.318-9.318
		c0-5.146,4.172-9.318,9.318-9.318C307.196,179.627,311.368,183.799,311.368,188.945z"
      />
      <path
        opacity="0.32"
        fill="#FFFFFF"
        d="M338.47,188.945c0,5.146-4.172,9.318-9.318,9.318s-9.318-4.172-9.318-9.318
		c0-5.146,4.172-9.318,9.318-9.318S338.47,183.799,338.47,188.945z"
      />
      <path
        fill="none"
        stroke="#E16450"
        strokeMiterlimit="10"
        d="M311.368,188.945c0,5.146-4.172,9.318-9.318,9.318
		c-5.146,0-9.318-4.172-9.318-9.318c0-5.146,4.172-9.318,9.318-9.318C307.196,179.627,311.368,183.799,311.368,188.945z"
      />
      <path
        fill="none"
        stroke="#E16450"
        strokeMiterlimit="10"
        d="M338.47,188.945c0,5.146-4.172,9.318-9.318,9.318
		s-9.318-4.172-9.318-9.318c0-5.146,4.172-9.318,9.318-9.318S338.47,183.799,338.47,188.945z"
      />
      <line
        fill="none"
        stroke="#E16450"
        strokeMiterlimit="10"
        x1="310.626"
        y1="185.297"
        x2="320.575"
        y2="185.297"
      />
      <path
        fill="#FFFFFF"
        d="M345.086,317.293l-40.179,8.554c-2.161,0.46-4.286-0.919-4.746-3.08l-11.629-54.624
		c-0.46-2.161,0.919-4.286,3.08-4.746l40.179-8.554c2.161-0.46,4.286,0.919,4.746,3.08l11.629,54.624
		C348.626,314.708,347.247,316.833,345.086,317.293z"
      />
      <circle fill="#6C9BAA" cx="316.643" cy="288.152" r="8.808" />
      <g>
        <path
          fill="#FFCD9B"
          d="M328.802,302.42c-4.63,0-8.532-0.188-10.977-0.346c-4.496-0.29-7.906-4.169-7.618-8.665
			c0.288-4.498,4.175-7.904,8.664-7.622c16.204,1.038,34.031-0.898,38.085-4.014c-5.553-24.969-17.292-42.42-17.41-42.595
			c-2.534-3.726-1.569-8.802,2.158-11.337c3.727-2.536,8.802-1.569,11.337,2.158c0.564,0.829,13.875,20.621,20.023,49.027
			c1.023,4.724-0.137,9.324-3.263,12.953C362.207,300.794,342.38,302.42,328.802,302.42z M357.114,282.491h0.016H357.114z"
        />
      </g>
      <g>
        <path
          fill="#FFCD9B"
          d="M309.742,313.733c-0.489,0-0.984-0.045-1.482-0.135c-6.051-1.11-36.715-7.358-46.046-21.349
			c-2.735-4.101-3.66-8.673-2.676-13.222c6.039-27.904,15.902-47.344,16.319-48.157c2.057-4.013,6.972-5.596,10.984-3.543
			c4.009,2.054,5.595,6.967,3.545,10.977c-0.114,0.223-9.34,18.506-14.896,44.176c-0.011,0.048-0.042,0.196,0.302,0.713
			c4.051,6.083,23.529,12.161,35.417,14.353c4.431,0.817,7.362,5.071,6.548,9.502C317.033,310.979,313.603,313.731,309.742,313.733z
			"
        />
      </g>
    </g>
    <g>
      <g>
        <g>
          <g>
            <path
              fill="#FFAF82"
              d="M130.43,278.19c-9.736,0.002-15.082-2.684-17.41-8.143c-3.833-8.989,3.771-19.814,26.249-37.365
					c13.631-10.643,27.624-19.672,28.212-20.051c3.54-2.28,8.252-1.254,10.53,2.282c2.278,3.537,1.256,8.252-2.281,10.53
					c-21.261,13.692-41.16,29.765-46.861,37.515c3.977,0.183,12.467-0.39,29.883-4.546c4.099-0.974,8.202,1.55,9.179,5.642
					c0.976,4.093-1.55,8.202-5.642,9.179C148.498,276.524,138.101,278.19,130.43,278.19z"
            />
          </g>
          <g>
            <path
              fill="#C8E6EB"
              d="M178.306,214.722c-2.383-3.702-7.314-4.771-11.016-2.388c-0.589,0.379-14.595,9.417-28.239,20.069
					c-22.628,17.669-30.265,28.615-26.355,37.781c2.388,5.598,7.834,8.349,17.745,8.349c5.798,0,13.126-0.942,22.261-2.807V259.43
					c-12.618,2.739-19.504,3.268-23.14,3.198c6.141-7.883,25.638-23.545,46.358-36.888
					C179.621,223.356,180.69,218.423,178.306,214.722z"
            />
          </g>
          <path
            fill="#C8E6EB"
            d="M172.236,210.588h59.214v94.025c0,2.19-1.775,3.966-3.966,3.966h-51.282c-2.19,0-3.966-1.776-3.966-3.966
				V210.588z"
          />

          <rect
            id="XMLID_2720_"
            x="210.205"
            y="419.263"
            transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 434.4606 880.4256)"
            fill="#F9C388"
            width="14.05"
            height="41.9"
          />

          <rect
            id="XMLID_2719_"
            x="180.772"
            y="419.263"
            transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 375.5938 880.4256)"
            fill="#F9C388"
            width="14.05"
            height="41.9"
          />

          <rect
            id="XMLID_2718_"
            x="206.455"
            y="308.526"
            transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 435.8152 748.8454)"
            fill="#55C7A6"
            width="22.905"
            height="131.794"
          />

          <rect
            id="XMLID_2717_"
            x="174.39"
            y="308.526"
            transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 371.685 748.8454)"
            fill="#55C7A6"
            width="22.905"
            height="131.794"
          />

          <rect
            id="XMLID_2716_"
            x="174.39"
            y="308.526"
            transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 403.7501 644.0737)"
            fill="#55C7A6"
            width="54.97"
            height="27.022"
          />

          <rect
            id="XMLID_2715_"
            x="206.455"
            y="439.211"
            transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 438.6244 885.6812)"
            fill="#96DBBF"
            width="25.714"
            height="7.26"
          />

          <rect
            id="XMLID_2714_"
            x="171.581"
            y="439.211"
            transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 368.876 885.6812)"
            fill="#96DBBF"
            width="25.714"
            height="7.26"
          />
          <path
            id="XMLID_2713_"
            fill="#3F3837"
            d="M208.525,469.27h39.922v-3.78c0-3.141-2.546-5.687-5.687-5.687h-34.235V469.27z"
          />

          <rect
            id="XMLID_2712_"
            x="220.428"
            y="453.377"
            transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 453.0771 915.4283)"
            fill="#3F3837"
            width="12.221"
            height="8.675"
          />
          <path
            id="XMLID_2711_"
            fill="#3F3837"
            d="M196.412,469.27H156.49v-3.78c0-3.141,2.546-5.687,5.687-5.687h34.235V469.27z"
          />
          <rect
            id="XMLID_2710_"
            x="172.288"
            y="453.377"
            fill="#3F3837"
            width="12.221"
            height="8.675"
          />

          <rect
            id="XMLID_2709_"
            x="207.46"
            y="469.27"
            transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 458.6837 941.3984)"
            fill="#232222"
            width="43.765"
            height="2.859"
          />

          <rect
            id="XMLID_2708_"
            x="154.569"
            y="469.27"
            transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 352.9021 941.3984)"
            fill="#232222"
            width="43.765"
            height="2.859"
          />
          <path
            fill="#FFAF82"
            d="M193.992,208.608c0,3.985,3.231,7.216,7.216,7.216c3.985,0,7.216-3.231,7.216-7.216v-8.114h-14.431
				V208.608z"
          />
          <path
            id="XMLID_2707_"
            fill="#FFAF82"
            d="M219.313,172.853c0,4.501,3.649,8.149,8.149,8.149c4.501,0,8.149-3.648,8.149-8.149
				s-3.649-8.149-8.149-8.149C222.961,164.704,219.313,168.352,219.313,172.853z"
          />
          <path
            id="XMLID_2706_"
            fill="#FFAF82"
            d="M168.139,172.853c0,4.501,3.649,8.149,8.149,8.149c4.5,0,8.149-3.648,8.149-8.149
				s-3.649-8.149-8.149-8.149C171.788,164.704,168.139,168.352,168.139,172.853z"
          />
          <path
            id="XMLID_2705_"
            fill="#FFCD9B"
            d="M190.725,205.04h21.936c9.114,0,16.502-7.388,16.502-16.502v-42.637
				c0-2.892-2.344-5.236-5.236-5.236h-49.704v47.873C174.223,197.652,181.612,205.04,190.725,205.04z"
          />
          <path
            id="XMLID_2704_"
            fill="#FFFFFF"
            d="M210.635,182.665c0.938,0.005,1.504,1.032,1.021,1.836
				c-2.037,3.387-5.754,5.645-9.993,5.624c-4.239-0.021-7.934-2.316-9.937-5.723c-0.475-0.808,0.101-1.83,1.039-1.826
				L210.635,182.665z"
          />
          <g id="XMLID_2702_">
            <defs>
              <path
                id="XMLID_2703_"
                d="M210.635,182.665c0.938,0.005,1.504,1.032,1.021,1.836c-2.037,3.387-5.754,5.645-9.993,5.624
						c-4.239-0.021-7.934-2.316-9.937-5.723c-0.475-0.808,0.101-1.83,1.039-1.826L210.635,182.665z"
              />
            </defs>
            <clipPath id="XMLID_15_">
              <use xlinkHref="#XMLID_2703_" overflow="visible" />
            </clipPath>
          </g>
          <circle id="XMLID_2701_" fill="#654932" cx="188.901" cy="169.48" r="2.066" />
          <circle id="XMLID_2700_" fill="#654932" cx="215.137" cy="169.48" r="2.066" />
          <path
            id="XMLID_2699_"
            fill="#965028"
            d="M173.368,148.42h42.163c5.746,0,10.404-4.658,10.404-10.404v-6.915h-42.163
				c-5.746,0-10.404,4.658-10.404,10.404V148.42z"
          />
          <path
            fill="#965028"
            d="M172.268,139.513c0-4.272,2.987-7.97,7.166-8.856c0.004-0.001,0.008-0.002,0.011-0.002l39.678-8.372
				c1.975,9.359-4.011,18.546-13.37,20.521l-33.486,7.066V139.513z"
          />
          <path
            id="XMLID_2698_"
            fill="#965028"
            d="M172.268,169.697h1.904c1.582,0,2.865-1.283,2.865-2.865V148.42h-4.769V169.697z"
          />
          <path
            id="XMLID_2697_"
            fill="#965028"
            d="M228.828,173.033h1.807v-21.04h-4.525v18.321
				C226.109,171.816,227.326,173.033,228.828,173.033z"
          />
          <path
            id="XMLID_2696_"
            fill="#965028"
            d="M224.399,154.654h6.236V144.38c0-4.528-3.671-8.198-8.198-8.198h-7.419v9.091
				C215.017,150.454,219.217,154.654,224.399,154.654z"
          />
          <polygon
            fill="#2B2929"
            points="199.115,220.806 203.428,220.806 204.403,215.265 198.012,215.265 			"
          />
          <polygon
            fill="#2B2929"
            points="197.152,287.856 197.152,223.472 199.774,219.026 202.849,219.026 205.486,223.472
				205.486,287.856 201.319,294.019 			"
          />
          <polygon
            fill="#FFFFFF"
            points="208.423,206.273 202.435,215.189 210.927,223.472 212.002,208.945 			"
          />
          <polygon
            fill="#FFFFFF"
            points="193.99,206.273 199.978,215.189 192.343,223.472 190.411,208.945 			"
          />
          <polygon
            opacity="0.24"
            fill="#232222"
            points="229.36,308.7 174.39,308.7 174.39,313.326 			"
          />
          <g>
            <path
              fill="#FFAF82"
              d="M236.73,301.753c-11.219,0-22.221-4.485-23.997-5.242c-4.037-1.72-5.915-6.388-4.195-10.425
					c1.722-4.035,6.387-5.913,10.424-4.194c7.226,3.051,20.038,5.938,24.555,2.421c2.735-2.128,2.281-8.082,1.768-11.441
					c-3.916-25.619-21.386-48.442-21.562-48.669c-2.683-3.468-2.052-8.458,1.414-11.144c3.465-2.689,8.448-2.062,11.138,1.4
					c0.821,1.056,20.163,26.221,24.718,56.01c2.304,15.07-2.938,22.677-7.742,26.403C248.551,300.52,242.61,301.751,236.73,301.753z
					"
            />
          </g>
          <path
            fill="#C8E6EB"
            d="M228.104,283.957c6.036,1.395,12.252,1.802,14.921-0.275c1.762-1.372,2.297-5.269,1.469-10.69
				c-3.866-25.283-21.067-47.861-21.401-48.295c-2.96-3.813-2.271-9.305,1.541-12.268c3.817-2.965,9.31-2.274,12.271,1.539
				c0.827,1.063,20.292,26.388,24.878,56.381c2.362,15.455-3.069,23.298-8.042,27.156c-4.926,3.822-11.051,5.116-17.093,5.116
				c-4.267,0-8.483-0.65-12.21-1.507L228.104,283.957z"
          />
        </g>
        <path
          fill="#3C5A73"
          d="M244.696,274.553h-36.273v7.982h-48.742c-4.408,0-7.982-3.574-7.982-7.982H244.696z"
        />
        <path
          fill="#46738C"
          d="M172.501,282.535h60.6c2.73,0,5.067-1.957,5.547-4.644l5.959-33.369c0.617-3.454-2.039-6.625-5.547-6.625
			h-53.87c-2.73,0-5.067,1.957-5.547,4.644L172.501,282.535z"
        />
      </g>
      <path
        opacity="0.41"
        fill="#FFFFFF"
        d="M193.294,178.603h-12.978c-2.267,0-4.105-1.838-4.105-4.105v-8.796h21.188v8.796
		C197.399,176.765,195.561,178.603,193.294,178.603z"
      />
      <path
        opacity="0.41"
        fill="#FFFFFF"
        d="M223.584,178.603h-12.978c-2.267,0-4.105-1.838-4.105-4.105v-8.796h21.188v8.796
		C227.689,176.765,225.851,178.603,223.584,178.603z"
      />
      <path
        fill="none"
        stroke="#50555A"
        strokeWidth="0.9377"
        strokeMiterlimit="10"
        d="M193.294,178.603h-12.978
		c-2.267,0-4.105-1.838-4.105-4.105v-8.796h21.188v8.796C197.399,176.765,195.561,178.603,193.294,178.603z"
      />
      <path
        fill="none"
        stroke="#50555A"
        strokeWidth="0.9377"
        strokeMiterlimit="10"
        d="M223.584,178.603h-12.978
		c-2.267,0-4.105-1.838-4.105-4.105v-8.796h21.188v8.796C227.689,176.765,225.851,178.603,223.584,178.603z"
      />
      <line
        fill="none"
        stroke="#965028"
        strokeMiterlimit="10"
        x1="181.791"
        y1="160.025"
        x2="195.194"
        y2="160.025"
      />
      <line
        fill="none"
        stroke="#965028"
        strokeMiterlimit="10"
        x1="207.299"
        y1="160.025"
        x2="220.702"
        y2="160.025"
      />

      <line
        fill="none"
        stroke="#50555A"
        strokeWidth="0.9377"
        strokeMiterlimit="10"
        x1="197.399"
        y1="167.647"
        x2="206.502"
        y2="167.647"
      />
    </g>
    <rect x="0.974" y="471.316" fill="#0A4160" width="480.743" height="5.446" />
  </svg>
);

export default HeroImage;
